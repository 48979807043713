document.addEventListener("DOMContentLoaded", function () {
  var jumpLinks = document.querySelectorAll('.jump');
  jumpLinks.forEach(function (jumpLink) {
  jumpLink.addEventListener("click", function (event) {
  event.preventDefault();
  var target = this.getAttribute("href");
  var targetElement = document.querySelector(target);
  var cols = document.querySelectorAll(".col");
  cols.forEach(function (col) {
  col.classList.remove("bg-secondary");
  col.classList.add("bg-light");
  });
  var listItems = document.querySelectorAll(".col > ul > li");
  listItems.forEach(function (listItem) {
  listItem.classList.remove("text-white");
  });
  var links = document.querySelectorAll(".col > ul > li > a");
  links.forEach(function (link) {
  link.classList.remove("text-white");
  });
  var ltrs = document.querySelectorAll(".col .ltr");
  ltrs.forEach(function (ltr) {
  ltr.classList.remove("opacity-25");
  });
  targetElement.classList.toggle("bg-light");
  targetElement.classList.toggle("bg-secondary");
  var targetLinks = targetElement.querySelectorAll("ul > li > a");
  targetLinks.forEach(function (targetLink) {
  targetLink.classList.toggle("text-white");
  targetLink.classList.toggle("text-underline");
  });
  var targetLtrs = targetElement.querySelectorAll(".ltr");
  targetLtrs.forEach(function (targetLtr) {
  targetLtr.classList.toggle("opacity-25");
  });
  var targetListItems = targetElement.querySelectorAll("li");
  targetListItems.forEach(function (targetListItem) {
  targetListItem.classList.toggle("text-white");
  });
  // Scroll to the target element
  targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  });
  });
  });
